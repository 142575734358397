import { addEventDelegate } from "@jeffcarbine/premmio/public/modules/eventDelegate/eventDelegate.js";
import { dataEmit } from "@jeffcarbine/premmio/public/modules/dataEmit/dataEmit.js";

const setScrolledValue = (scrollPos) => {
  const threshold = 200;

  if (scrollPos > threshold) {
    dataEmit("pageScrolled", true);
  } else {
    dataEmit("pageScrolled", false);
  }
};

addEventDelegate("scroll", window, setScrolledValue);

// set sheen duration for btns
document.querySelectorAll(".btn").forEach((button) => {
  const width = button.offsetWidth;
  let duration = width / 100;

  if (duration > 2) {
    duration = 2;
  }

  button.style.setProperty("--sheen-duration", `${duration}s`);
});
